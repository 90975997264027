import { Card, Col, Form, Row } from "react-bootstrap"


const CardCheckBox = ({register, name, label}) => {
    return (
        <Card className="shadow-sm p-3 mb-5 bg-body rounded">
            <Card.Body>
                <Row>
                    <Col className='d-flex align-items-center justify-content-center'>
                        <Form.Check
                            type="checkbox"
                            name={name}
                            {...register(name)}
                            id={name}
                        />
                        <label for={name} className="fs-5 m-2">{label}</label>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export { CardCheckBox }