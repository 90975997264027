import TopBarProgress from 'react-topbar-progress-indicator'
import { Suspense } from "react";

import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { MasterLayoutForm } from "../../_metronic/layout/MasterLayoutForm"

import {NewOscPage} from "../modules/forms/new_osc/NewOscPage"


const SuspensedView = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
      barColors: {
        '0': baseColor,
      },
      barThickness: 1,
      shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export const formRouting = {
    path: "forms",
    element: <MasterLayoutForm/>,
    children:[
        {
            path:"new_osc",
            element:<SuspensedView> <NewOscPage/> </SuspensedView>
        }
    ]
}