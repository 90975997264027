/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { useLayout } from "../../core"
import { KTSVG, toAbsoluteUrl } from "../../../helpers"
import { AsideMenu } from "./AsideMenu"
import Image from 'react-bootstrap/Image';
import { useAuth } from "../../../../app/modules/auth/core/Auth"
import { HeaderUserMenu } from "../../../partials"

const AsideDefault = () => {
  const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px"
  const { config, classes } = useLayout()
  const { aside } = config
  const { currentUser } = useAuth()



  return (
    <div
      id="kt_aside"
      className={clsx("aside", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        {/* begin::Logo */}
        {aside.theme === "dark" && (
          <Link to="#">
            <Image
                src={toAbsoluteUrl("/media/avatars/connecting-food-logo-480x119.webp")}
                fluid
                style={{maxWidth: "70%"}}
            />
          </Link>
        )}
        {aside.theme === "light" && (
          <Link to="#">
            <Image
                src={toAbsoluteUrl("/media/avatars/connecting-food-logo-480x119.webp")}
                fluid
                style={{maxWidth: "70%"}}
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            <KTSVG
              path={"/media/icons/duotune/arrows/arr080.svg"}
              className={"svg-icon-1 rotate-180"}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div
        className="aside-footer flex-column-auto pt-5 pb-7 px-5"
        id="kt_aside_footer"
      >
        <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <HeaderUserMenu />
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <div className="symbol symbol-50px">
            <div className="symbol-label fs-2 fw-bold text-success">{`${currentUser.first_name[0]}${currentUser.last_name[0] || ''}`}</div>
          </div>
        </div>
          <div className="aside-logo fw-bolder d-flex align-items-center fs-5" id="kt_aside_logo">
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
        {/* end::Toggle */}
      </div>
        
      </div>
      {/* end::Footer */}
    </div>
  )
}

export { AsideDefault }
