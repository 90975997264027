/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

const StatisticsWidget5 = ({
  className,
  color,
  title,
  description
}) => {
  return (
    <a href="#" className={`card bg-${color} hoverable ${className}`}>
      {/* begin::Body */}
      <div className="card-body">

        {/* <div className={`text-inverse-${color} fw-bolder mb-2`} style={{fontSize: 40}}> */}
        <div className={`text-inverse-${color} fw-bolder mb-2 fs-1`}>
          {title}
        </div>

        <div className={`fw-bold text-inverse-${color} fs-7`}>
          {description}
        </div>
      </div>
      {/* end::Body */}
    </a>
  )
}

export { StatisticsWidget5 }
