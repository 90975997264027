/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import { useAuth } from "../core/Auth"
import Image from 'react-bootstrap/Image';
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { getUserByToken, login } from "../core/_requests"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { InputForm } from "../../../_core/components/InputForm"

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [loginFail, setLoginFail] = useState(false)
  const { saveAuth, setCurrentUser, register, getValues, handleSubmit, errors } = useAuth()

  const onSubmit = async () => {
    const values = getValues()
    setLoading(true)
    try {
      const { data: auth } = await login(values.username, values.password)
      saveAuth(auth)
      const { data: user } = await getUserByToken(auth.access)
      setCurrentUser(user)
    } catch (error) {
      setLoginFail(true)
      saveAuth(undefined)
      setLoading(false)
    }
  }

  return (
    <>
      <div className="text-center">
        <Image
            src={toAbsoluteUrl("/media/avatars/connecting-food-logo-480x119.webp")}
            fluid
            style={{maxWidth: "70%"}}
        />
      </div>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} className="w-100 mt-3">
      {loginFail && (
      <div className='mb-lg-15 alert alert-danger'>
        <div className='alert-text font-weight-bold'>Usuario ou senha invalidos!</div>
      </div>
      )}
      <Row>
        <Col>
            <InputForm
                label="Email"
                placeholder="Email"
                name="username"
                type="text"
                errors={errors}
                register={register}
            />
        </Col>
      </Row>
      
      <Row>
        <Col>
          <InputForm 
              label="Senha"
              placeholder="Senha"
              name="password"
              type="password"
              errors={errors}
              register={register}
          />
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-center">
        <Col xs={4} className="d-grid gap-2">
          <Button type="submit" style={{background: `linear-gradient(90deg, #fc823d, #f0a632)`}}> 
          {loading ? (
            <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Carregando...
            </>
          ) : (
            <span>Entrar</span>
          )}
            
          </Button>
        </Col>
      </Row>
        
      </Form>
    </>
  )
}
