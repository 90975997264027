import { Card, Col, Row, Spinner } from "react-bootstrap"
import { InputForm } from "../../../../../../_core/components/InputForm"
import { RadioButton } from "../../../../../../_core/components/RadioButton"
import { useNewOscQuery } from "../../../_core/NewOscQueryProvider"
import { useNewOsc } from "../../../_core/NewOscProvider"
import { CheckBoxGroup } from "../../../../../../_core/components/CheckBoxGroup"

const TargetPublicStep = () => {
    const { register, errors, watch, control, formatDataToSelect, showAgeGroupInput } = useNewOsc()
    const { useGetTargetAudience, useGetAgeGroup } = useNewOscQuery()

    const { isLoading: isLoadingTargetAudience, data: dataTargetAudience } = useGetTargetAudience()
    const { isLoading: isLoadingAgeGroup, data: dataAgeGroup } = useGetAgeGroup()

    
    if(isLoadingTargetAudience || isLoadingAgeGroup){
        return (
            <>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <p className='fs-3 fw-bolder'>Carregando... </p>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title><span className="fs-1 fw-bolder text-start">Informações de público-alvo</span></Card.Title>
                </Card.Header>
                <Card.Body>

                    <Row>
                        <Col>
                            <CheckBoxGroup
                                name="target_audience_create"
                                placeholder="Selecione ou crie os seus Publicos-Alvo"
                                subLabel="Caso o seu Público-alvo não esteja na lista, adicione no campo abaixo (mais de uma alternativa pode ser selecionada)"
                                label="Público-Alvo"
                                watch={watch}
                                isCreatable
                                isMulti
                                errors={errors}
                                register={register}
                                control={control}
                                options={formatDataToSelect(dataTargetAudience)}
                            />
                        </Col>
                    </Row>

                    <Row>
                    <Col>
                        <CheckBoxGroup
                            name="age_group__id"
                            placeholder="Selecione as faixas etária"
                            label="Selecione os perfis de público atendido a partir da faixa etária (mais de uma alternativa pode ser selecionada)"
                            watch={watch}
                            errors={errors}
                            register={register}
                            control={control}
                            customMd={3}
                            isMulti
                            options={formatDataToSelect(dataAgeGroup)}
                        />
                    </Col>
                    {dataAgeGroup.map((ageGroup) => {
                        if (showAgeGroupInput(ageGroup.id)){
                            return (
                                <Col xs={12}>
                                    <InputForm
                                        name={`age_group__quantity_people_served_monthly${ageGroup.id}`}
                                        label={`Qual é a quantidade de ${ageGroup.name} atendidas por mês?`}
                                        type="number"
                                        errors={errors}
                                        register={register}
                                    />
                                </Col>
                            )
                        }
                        return null
                    })}

                </Row>
                <Row>
                    <Col>
                        <RadioButton 
                                name="has_registration_form"
                                label="Possui ficha cadastral com dados socioeconômicos de todos os atendidos que participam das ações sociais e/ou recebem doações da OSC?"
                                subLabel="Dados socioenômicos - Exemplos: Nome, Idade, etnia, sexo, renda média da família, número de pessoas na família , etc…"
                                errors={errors}
                                register={register}
                            />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputForm 
                                name="people_benefited"
                                label="Quantas pessoas, em média por mês, se beneficiam dos alimentos fornecidos pela sua OSC (se alimentam no local e/ou recebem alimentos distribuídos como marmitas, sacolinhas, etc.)?"
                                type="number"
                                errors={errors}
                                register={register}
                            />
                    </Col>
                </Row>
                </Card.Body>
            </Card>

        </>
    )
}

export {TargetPublicStep}