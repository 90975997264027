import { NewOscFormWrapped } from "./new_osc_form/NewOscForm"
import { NewOscProvider } from "./_core/NewOscProvider"
import { NewOscQueryProvider } from "./_core/NewOscQueryProvider"


const NewOscPage = () => {
    return (
        <NewOscQueryProvider>
            <NewOscProvider>
                <NewOscFormWrapped/>
            </NewOscProvider>
        </NewOscQueryProvider>
    )
}

export {NewOscPage}