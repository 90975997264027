import { useAuth } from "../../../../app/modules/auth/core/Auth"

const HeaderUserMenu = () => {
  const { currentUser, logout } = useAuth()
  // const currentUser = {
  //   first_name: "admin",
  //   email: "admin@admin.com",

  // }
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item ">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px">
            <div className="symbol-label fs-2 fw-bold text-success">{`${currentUser.first_name[0]}${currentUser.last_name[0] || ''}`}</div>
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
            <a href="/" className="fw-bold text-muted text-hover-primary fs-7">
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        {/* logout */}
        <a onClick={logout} href="/" className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
