import Form from 'react-bootstrap/Form';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import { Controller } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';


const MultiSelect = ({options, label, subLabel, placeholder, name, type="text", errors, control, watch, register, ...rest}) => {

    let has_error = !!(Object.keys(errors).length && errors[name])
    let error_message = ""

    if(Object.keys(errors).length && name.includes(".")){
        const base = name.split(".")[0]
        const field_name = name.split(".")[1]
        if(errors[base]){
            has_error = Object.prototype.hasOwnProperty.call(errors[base] || {}, field_name)
            if(has_error){
                error_message = errors[base][field_name].message
            }
        }
    }

    if (Object.keys(errors).length && errors[name]){
        error_message = errors[name]?.message
    }

    const valueField = watch(name)

    const getCurrentData = (valueField) => {
        if(!valueField){
            return []
        }

        if(typeof valueField !== "object"){
            let currentOptions = options[valueField - 1]
            return {
                label: currentOptions?.label || valueField,
                value: valueField
            }
        }

        let currentOptions = options.filter(val => valueField?.some(selectedValue => val.value === selectedValue))
        if (currentOptions.length !== valueField.length){
            currentOptions = valueField.map((val) => {
                if(!currentOptions?.some(selectedValue => val === selectedValue.value)){
                    return {
                        label: val,
                        value: val
                    }
                }
                return currentOptions.filter((currentOption) => currentOption.value === val)[1]
            })
        }
        return currentOptions

    }

    return (
        <Form.Group controlId={name}>
            {label && (
                <Form.Label>
                    <Row>
                        <Col>
                            <span className='fs-5'>{label}</span>
                        </Col>
                    </Row>
                    {subLabel && (
                        <Row>
                            <Col>
                                <span className="text-muted fs-7">{subLabel}</span>
                            </Col>
                        </Row>
                    )}
                </Form.Label>
            )}
            <Controller
                name={name}
                control={control}
                {...register(name)}
                isInvalid={has_error}
                render={({ field: { onChange, value, ref, ...fieldRest}}) => {
                    return (rest.isCreatable) ? (
                        <CreatableSelect
                            {...rest}
                            {...fieldRest} 
                            classNamePrefix='react-select'
                            className={has_error ? "react-select-styled rounded border border-danger": "react-select-styled"}
                            placeholder={placeholder}
                            isClearable
                            options={options}
                            value={getCurrentData(valueField)}
                            inputRef={ref}
                            onChange={(val) => {
                                if (val === null){
                                    return onChange(null)
                                }
                                return onChange((val?.length) ? val.map(c => c.value) : val.value)
                            }}
                        />

                    ) : (
                        <Select
                            {...rest}
                            {...fieldRest}
                            classNamePrefix='react-select'
                            className={has_error ? "react-select-styled rounded border border-danger": "react-select-styled"}
                            placeholder={placeholder}
                            isClearable
                            options={options}
                            inputRef={ref}
                            value={getCurrentData(valueField)}
                            onChange={(val) => {
                                if (val === null){
                                    return onChange(null)
                                }
                                return onChange((val?.length) ? val.map(c => c.value) : val.value)
                            }}
                        />
                    )
                    
                }
                }
            />
            {has_error && (
                <span className='text-danger'>
                    {error_message}
                </span>

            )}
            
        </Form.Group>
    )
}

export {MultiSelect}