import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { MasterLayout } from "../../_metronic/layout/MasterLayout"

import { RegisteredFormsWrapper } from "../pages/form/RegisteredFormsWrapper";
import { FormDetailsWrapper } from "../pages/form/FormDetailsWrapper";

const SuspensedView = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
      barColors: {
        '0': baseColor,
      },
      barThickness: 1,
      shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export const privateRoutes = {
    element: <MasterLayout/>,
    children: [
        {
            path: "login",
            element: <Navigate to='/forms/list' />
        },
        {
            path: "/forms/list",
            element: <>
            <SuspensedView> <RegisteredFormsWrapper/> </SuspensedView>
            </>
        },
        {
            path: "/forms/new-osc",
            element: <>
            <SuspensedView> <FormDetailsWrapper/> </SuspensedView>
            </>
        },
        {
            path: "/",
            element: <Navigate to='/forms/list' />
        },
    ]
}

