/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

const MegaMenu = () => (
  <div className="row" data-kt-menu-dismiss="true">
    <div className="col-lg-4 border-left-lg-1">
      <div className="menu-inline menu-column menu-active-bg">
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 border-left-lg-1">
      <div className="menu-inline menu-column menu-active-bg">
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 border-left-lg-1">
      <div className="menu-inline menu-column menu-active-bg">
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/" className="menu-link">
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
            <span className="menu-title">Example link</span>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export { MegaMenu }
