/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react"
import { Outlet } from "react-router-dom"

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-white")
    return () => {
      document.body.classList.remove("bg-white")
    }
  }, [])

  return (
    <div
      className="d-flex flex-column flex-column-fluid"
      style={{background: `linear-gradient(135deg, #ff9b70, #f2c363)`, minHeight: "100%"}}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 h-100">
        {/* begin::Wrapper */}
        <div className=" bg-white rounded shadow-sm p-10 p-lg-15">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
    </div>
  )
}

export { AuthLayout }
