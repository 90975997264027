import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { Col, Container, Row } from 'react-bootstrap';

const PrivacyModal = ({showState, handleCloseState}) => {
    return (
        <Modal show={showState} onHide={handleCloseState} backdrop="static" keyboard={false} size="lg">
            <Modal.Header className='d-flex justify-content-center'>
            <Image
                src={toAbsoluteUrl("/media/svg/misc/CF-favicon.svg")}
                fluid
                style={{maxWidth: "20%"}}
            />
            </Modal.Header>
            <Modal.Body>
                <Container className='m-2'>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            <p className='fs-3 fw-bolder'>Política de Privacidade</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <p className='fs-4 text-md-start'>Ao confirmar o cadastro da Organização da Sociedade Civil - OSC que represento neste formulário, autorizo a Connecting Food (CF) a realizar tratamento dos dados informados para que possa incluí-la em seu banco de dados.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <p className='fs-4 text-md-start'>Além disso, estou ciente que a CF poderá compartilhar os dados da minha OSC com seus parceiros envolvidos, além de que poderão tomar decisões com base nos dados informados.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <p className='fs-4 text-md-start'>Estou ciente que os dados da minha OSC poderão ser utilizados para enriquecimento da base de dados controlada pela CF. O cadastro na base de dados da CF não caracteriza, sob nenhuma hipótese, o fornecimento de alimentos para a OSC, seja via doações ou não.</p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
            <Button variant="primary" onClick={handleCloseState}>
                Entendi
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export {PrivacyModal}