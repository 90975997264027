import axios from "axios";


const CNPJ_API = process.env.REACT_APP_CNPJ_API
const CEP_API = process.env.REACT_APP_CEP_API
const BACKEND_API = process.env.REACT_APP_BACKEND_API

export const getCNPJInfo = async cnpj => 
    axios.get(`${CNPJ_API}/${cnpj}`).then(resp => resp.data)

export const getCepInfo = async cep => 
    axios.get(`${CEP_API}/${cep}/json/`).then(resp => resp.data)

export const getTargetAudience = async () => 
    axios.get(`${BACKEND_API}/target-audience/`).then(resp => resp.data)

export const getRecessMounth = async () => 
    axios.get(`${BACKEND_API}/recess-mounth/`).then(resp => resp.data)

export const getAgeGroup = async () => 
    axios.get(`${BACKEND_API}/age-group/`).then(resp => resp.data)

export const getMealType = async () => 
    axios.get(`${BACKEND_API}/meal-type/`).then(resp => resp.data)

export const getDistributionType = async () => 
    axios.get(`${BACKEND_API}/distribution-type/`).then(resp => resp.data)
    
export const getEducatinalUsageType = async () => 
        axios.get(`${BACKEND_API}/educational-usage-type/`).then(resp => resp.data)

export const getOtherPartnerFoodType = async () => 
        axios.get(`${BACKEND_API}/other-partner-food-type/`).then(resp => resp.data)

export const getOtherPartnerType = async () => 
        axios.get(`${BACKEND_API}/other-partner-type/`).then(resp => resp.data)

export const getOtherPartnerFrequencyReceved = async () => 
        axios.get(`${BACKEND_API}/other-partner-frequency-receved/`).then(resp => resp.data)

export const getDonationExtractionTransportType = async () => 
        axios.get(`${BACKEND_API}/donation-extraction-transport-type/`).then(resp => resp.data)

export const createNewOscForm = async (data) => {
    return axios.post(`${BACKEND_API}/new-osc-form/`, data.queryKey[1].data).then(resp => resp.data)
}

export const preSaveNewOscForm = async (data) => {
    if (data.queryKey[1].data.id){
        return axios.put(`${BACKEND_API}/pre-save/${data.queryKey[1].data.id}/`, data.queryKey[1].data).then(resp => resp.data)
    }
    return axios.post(`${BACKEND_API}/pre-save/`, data.queryKey[1].data).then(resp => resp.data)
}

