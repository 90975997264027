import { createContext, useContext } from "react"
import { useQuery } from "react-query"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'

import { 
  getNewOscAnswersCount,
  getNewOscCityCount,
  getNewOscAnswersCountByMonth,
  getNewOscAnswersCountByCity, 
} from "./_requests"

const FormDetailContext = createContext(null)

const FormDetailProvider = ({ children }) => {

  const filterSchema = () => Yup.object().shape({
  })
  
  const {
      control,
      watch,
      formState: {errors},
  } = useForm( { resolver: yupResolver(filterSchema()) } )
  
  const useGetNewOscAnswers = () => {
    return useQuery('new-osc-answers', getNewOscAnswersCount, {refetchOnWindowFocus: false})
  }
  const useGetNewOscCityCount = () => {
    return useQuery('new-osc-answers-by-city', getNewOscCityCount, {refetchOnWindowFocus: false})
  }
  const useGetNewOscAnswersCountByMonth = () => {
    return useQuery('new-osc-answers-by-month', getNewOscAnswersCountByMonth, {refetchOnWindowFocus: false})
  }
  const useGetNewOscAnswersCountByCity = () => {
    return useQuery('new-osc-cities', getNewOscAnswersCountByCity, {refetchOnWindowFocus: false})
  }

  return (
    <FormDetailContext.Provider
      value={{
        useGetNewOscAnswers,
        useGetNewOscCityCount,
        useGetNewOscAnswersCountByMonth,
        useGetNewOscAnswersCountByCity,
        control,
        watch,
        errors
      }}
    >
      {children}
    </FormDetailContext.Provider>
  )
}

const useFormDetail = () => useContext(FormDetailContext)

export { FormDetailProvider, useFormDetail }
