import { Card, CardGroup, Col, Row, Spinner } from "react-bootstrap"
import { InputForm } from "../../../../../../_core/components/InputForm"
import { MultiSelect } from "../../../../../../_core/components/MultiSelect"
import { useNewOscQuery } from "../../../_core/NewOscQueryProvider"
import { useNewOsc } from "../../../_core/NewOscProvider"
import { CardCheckBox } from "../../../../../../_core/components/CardCheckBox"
import { ConditionalCheckForm } from "../../../../../../_core/components/ConditionalCheckForm"
import { CheckBoxGroup } from "../../../../../../_core/components/CheckBoxGroup"


const FoodManagmentStep = () => {
    const { register, errors, watch, setValue, control, formatDataToSelect } = useNewOsc()
    const { useGetMealType, useGetDistributionType, useGetEducatinalUsageType } = useNewOscQuery()
    
    const { isLoading: isLoadingMealType, data: dataMealType } = useGetMealType()
    const { isLoading: isLoadingDistributionType, data: dataDistributionType } = useGetDistributionType()
    const { isLoading: isLoadingEducatinalUsageType, data: dataEducatinalUsageType } = useGetEducatinalUsageType()


    const timesWeek = [
        {label: "1", value: 1},
        {label: "2", value: 2},
        {label: "3", value: 3},
        {label: "4", value: 4},
        {label: "5", value: 5},
        {label: "6", value: 6},
        {label: "7", value: 7},
    ]
    
    if(isLoadingMealType || isLoadingDistributionType || isLoadingEducatinalUsageType){
        return (
            <>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <p className='fs-3 fw-bolder'>Carregando... </p>
                    </Col>
                </Row>
            </>
        )
    }

  const distribution_type_create = watch("food_distribution.distribution_type_create")

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title><span className="fs-1 fw-bolder text-start">Manuseio de alimentos</span></Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Card.Title>
                                        <Row>
                                            <Col>
                                                <Card.Title><span className="fs-1 fw-bolder text-start">Manipulação de alimentos</span></Card.Title>
                                                <span className="fs-4 text-start text-muted">Assinale quais destas alternativas a entidade possui</span>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <CardGroup>
                                                <CardCheckBox
                                                    register={register}
                                                    name="has_adequate_space"
                                                    label=" Espaço adequado para armazenamento de alimentos."
                                                />
                                                <CardCheckBox
                                                    register={register}
                                                    name="has_nutritionist"
                                                    label="Profissionais com formação em Nutrição e/ou Gastronomia."
                                                />
                                                <CardCheckBox
                                                    register={register}
                                                    name="has_freezing_system"
                                                    label=" Sistema de refrigeração (geladeira e/ou freezer)."
                                                />
                                            </CardGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CardGroup>
                                                <CardCheckBox
                                                    register={register}
                                                    name="has_local_kitchen"
                                                    label="Cozinha."
                                                />
                                                <CardCheckBox
                                                    register={register}
                                                    name="has_refectory"
                                                    label="Refeitório."
                                                />
                                            </CardGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ConditionalCheckForm
                                                label="Servem refeições no local?"
                                                id="serve_local_meals"
                                                name="serve_local_meals"
                                                register={register}
                                                watch={watch}
                                                setValue={setValue}
                                            >
                                                <Row className="w-100 d-flex align-items-center pt-4 pb-4">
                                                    <Col xs={4}>
                                                        <span className="fs-3 fw-bolder">Refeições</span>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <span className="fs-3 fw-bolder">Pessoas servidas/Mês</span>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <span className="fs-3 fw-bolder">Vezes Servidas na semana</span>
                                                    </Col>
                                                </Row>
                                                {dataMealType.map((mealType) => (
                                                    <Row className="border rounded">
                                                        <Col xs={4} className='d-flex align-items-center'>
                                                            <span className="fs-5">{mealType.name}</span>
                                                        </Col>                                        
                                                        <Col xs={4} className='d-flex align-items-center'>
                                                            <InputForm
                                                                name={`meal_type[${mealType.id}].people_server_daily`}
                                                                type="number"
                                                                errors={errors}
                                                                register={register}
                                                            />
                                                        </Col>
                                                        <Col xs={4} className='d-flex align-items-center'>
                                                            <MultiSelect
                                                                name={`meal_type[${mealType.id}].week_serves`}
                                                                placeholder="Selecione uma quantidade"
                                                                watch={watch}
                                                                errors={errors}
                                                                register={register}
                                                                control={control}
                                                                options={timesWeek}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                
                                            </ConditionalCheckForm>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row className="mt-4">
                        <Col>
                            <ConditionalCheckForm
                                label="Sua organização distribui alimentos para pessoas em situação de vulnerabilidade?"
                                id="check-recess"
                                name="food_distribution.has_food_distribution"
                                register={register}
                                setValue={setValue}
                                watch={watch}
                            >
                                <Row>
                                    <Col xs={12} className="mt-2">
                                        <CheckBoxGroup
                                            name="food_distribution.distribution_type_create"
                                            placeholder="Selecione os tipos de alimentos"
                                            label="Indique qual tipo de distribuição sua organização realiza (mais de uma alternativa pode ser selecionada)"
                                            watch={watch}
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            customMd={4}
                                            isMulti
                                            isCreatable
                                            options={formatDataToSelect(dataDistributionType)}
                                        />
                                    </Col>
                                    {distribution_type_create && distribution_type_create?.map((ageGroup) => {
                                        const currentData = dataDistributionType.filter((data) => data.id.toString() === ageGroup)
                                        if (currentData?.length){
                                            return (
                                                <Col xs={12}>
                                                    <InputForm
                                                        name={`food_distribution.monthly_distribution${currentData[0].id}`}
                                                        label={`Qual a quantidade total distribuída em média por mês de ${currentData[0].name} em sua organização?`}
                                                        type="number"
                                                        errors={errors}
                                                        register={register}
                                                    />
                                                </Col>
                                            )
                                        }
                                        return (
                                            <Col xs={12}>
                                                <InputForm
                                                    name={`food_distribution.monthly_distribution_custom[${distribution_type_create.indexOf(ageGroup)}]`}
                                                    label={`Qual a quantidade total distribuída em média por mês de ${ageGroup} em sua organização?`}
                                                    type="number"
                                                    errors={errors}
                                                    register={register}
                                                />
                                            </Col>
                                        )

                                    })}
                                    {/* <Col xs={12} className="mt-2">
                                        <InputForm
                                            name="food_distribution.monthly_distribution"
                                            type="text"
                                            label="Qual a quantidade total distribuída em média por mês em sua organização?"
                                            errors={errors}
                                            register={register}
                                        />
                                    </Col> */}
                                </Row>
                            </ConditionalCheckForm>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <ConditionalCheckForm
                                label="Realizam alguma atividade voltada para a alimentação com os atendidos (oficinas, aulas, etc)? "
                                id="has_educational_usage"
                                name="educational_usage.has_educational_usage"
                                register={register}
                                setValue={setValue}
                                watch={watch}
                            >
                                <Row>
                                    <Col xs={12} className="mt-2">
                                        <CheckBoxGroup
                                            name="educational_usage.educational_usage_type_create"
                                            placeholder="Selecione as atividades"
                                            label="Quais atividades voltadas para a alimentação são realizadas? (mais de uma alternativa pode ser selecionada)"
                                            watch={watch}
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            customMd={4}
                                            isMulti
                                            isCreatable
                                            options={formatDataToSelect(dataEducatinalUsageType)}
                                        />
                                    </Col>
                                    <Col xs={12} className="mt-2">
                                        <InputForm
                                            name="educational_usage.usage_details"
                                            type="text"
                                            as="textarea"
                                            min={100}
                                            rows={3}
                                            watch={watch}
                                            label="Por favor, conte-nos um pouco sobre as atividades realizadas"
                                            errors={errors}
                                            register={register}
                                        />
                                    </Col>
                                </Row>
                            </ConditionalCheckForm>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>

        </>
    )
}

export {FoodManagmentStep}