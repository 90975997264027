import React, { useEffect, useRef } from "react"
import ApexCharts from "apexcharts"
import { getCSSVariableValue } from "../../../../../../_metronic/assets/ts/_utils"

const CityBarChart = ({ data, chartHeight=200 }) => {
    const chartRef = useRef(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(chartHeight, data))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data, chartHeight])


    return (
        <div
          ref={chartRef}
          className="mixed-widget-7-chart card-rounded-bottom"
        ></div>
    )

}

function getChartOptions(height=200, data) {

    const categories = data.map((value) => value.city)
    const series_data = data.map((value) => value.count)

    const labelColor = getCSSVariableValue("--bs-gray-500")
    const borderColor = getCSSVariableValue("--bs-gray-200")
    const baseColor = getCSSVariableValue("--bs-info")
    const lightColor = getCSSVariableValue("--bs-light-info")

    return {
        series: [
        {
            name: "Respostas na cidade",
            data: series_data
        }
        ],
        chart: {
        fontFamily: "inherit",
        type: "bar",
        height: height,
        toolbar: {
            show: true
        }
        },
        plotOptions: {},
        legend: {
        show: false
        },
        dataLabels: {
        enabled: false
        },
        fill: {
        type: "solid",
        opacity: 1
        },
        stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor]
        },
        xaxis: {
        categories: categories,
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
        labels: {
            style: {
            colors: labelColor,
            fontSize: "12px"
            }
        },
        crosshairs: {
            position: "front",
            stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3
            }
        },
        tooltip: {
            enabled: true,
            formatter: undefined,
            offsetY: 0,
            style: {
            fontSize: "12px"
            }
        }
        },
        yaxis: {
        labels: {
            style: {
            colors: labelColor,
            fontSize: "12px"
            }
        }
        },
        states: {
        normal: {
            filter: {
            type: "none",
            value: 0
            }
        },
        hover: {
            filter: {
            type: "none",
            value: 0
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
            type: "none",
            value: 0
            }
        }
        },
        tooltip: {
        style: {
            fontSize: "12px"
        },
        y: {
            formatter: function(val) {
                return (val > 1 )? val + " Respostas" : val + " Resposta"
            }
        }
        },
        colors: [lightColor],
        grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
            lines: {
            show: true
            }
        }
        },
        markers: {
        strokeColors: baseColor,
        strokeWidth: 3
        }
    }
}
  

export {CityBarChart}