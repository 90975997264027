import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';
import { InputForm } from './InputForm';
import { useState } from 'react';



const CheckBoxGroup = ({options, label, subLabel, placeholder, name, type="text", errors, control, watch, register, ...rest}) => {

    const newOption = watch(`${name}_creatable`)
    const selectedOptions = watch(`${name}`)

    const checkSelected = () =>{
        if(selectedOptions){
            const non_default_values = selectedOptions.filter((val) => !options.some((optVal) => optVal.value == val))  
            if (non_default_values){
              non_default_values.forEach(element => {
                  options.push({
                      label: element,
                      value: element
                  })
              });
            }
        }
        return options
      }

    const [currentOptions, setCurrentOptions] = useState(checkSelected())

    let has_error = !!(Object.keys(errors).length && errors[name])
    let error_message = ""

    if(Object.keys(errors).length && name.includes(".")){
        const base = name.split(".")[0]
        const field_name = name.split(".")[1]
        if(errors[base]){
            has_error = Object.prototype.hasOwnProperty.call(errors[base] || {}, field_name)
            if(has_error){
                error_message = errors[base][field_name].message
            }
        }
    }

    if (Object.keys(errors).length && errors[name]){
        error_message = errors[name]?.message
    }

    const addCreatedToList = (newElement) => {
        if (currentOptions.some((val) => val.label === newElement)){
            return null
        }

        setCurrentOptions((prev) => [
            ...prev,
            {
                label: newElement,
                value: newElement
            }
        ])
    }

    return (
        <Form.Group controlId={name}>
            {label && (
                <Form.Label>
                    <Row>
                        <Col>
                            <span className='fs-5'>{label}</span>
                        </Col>
                    </Row>
                    {subLabel && (
                        <Row>
                            <Col>
                                <span className="text-muted fs-7">{subLabel}</span>
                            </Col>
                        </Row>
                    )}
                </Form.Label>
            )}
            <Row>
                {currentOptions.map((element, index) => (
                    <Col md={Math.ceil(rest.customMd || currentOptions.length / 6)} className='d-flex align-items-center'>
                        <Form.Check
                            key={`${name}${index}`}
                            type="checkbox"
                            name={name}
                            {...register(name)}
                            id={`${name}${index}`}
                            value={element.value}
                        />
                        {(element.label === element.value) ? (
                            <label for={`${name}${index}`} className="fs-5 m-2"><b>{element.label}</b></label>
                        ) : (
                            <label for={`${name}${index}`} className="fs-5 m-2">{element.label}</label>
                        )}
                    </Col>
                ))}
            </Row>
            {
                rest.isCreatable && (
                    <>
                        <Row className='mb-2'>
                            <Col md={9}>
                            <InputForm
                                name={`${name}_creatable`}
                                placeholder="Caso nao tenha a opção desejada, crie aqui"
                                type="text"
                                errors={errors}
                                register={register}
                            />
                            </Col>
                            <Col md={2} className='mx-auto'>
                                <Button variant="primary" onClick={() => {addCreatedToList(newOption)}}>
                                    Criar nova opção
                                </Button>
                            </Col>
                        </Row>
                        <hr></hr>
                    </>
                )
            }
            {has_error && (
                <span className='text-danger'>
                    {error_message}
                </span>

            )}
            
        </Form.Group>
    )
}

export {CheckBoxGroup}