import { Card, Col, Row, Spinner } from "react-bootstrap"
import { InputForm } from "../../../../../../_core/components/InputForm"
import { CheckBoxGroup } from "../../../../../../_core/components/CheckBoxGroup"
import { useNewOscQuery } from "../../../_core/NewOscQueryProvider"
import { useNewOsc } from "../../../_core/NewOscProvider"

const CompanyProfileStep = () => {
    const { register, errors, control, watch, formatDataToSelect } = useNewOsc()
    const { useGetRecessMounth } = useNewOscQuery()
    
    const { isLoading: isLoadingRecessMounth, data: dataRecessMounth } = useGetRecessMounth()

    if(isLoadingRecessMounth){
        return (
            <>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <p className='fs-3 fw-bolder'>Carregando... </p>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title><span className="fs-1 fw-bolder text-start">Perfil da empresa</span></Card.Title>
                </Card.Header>
                <Card.Body>

                    <Row>
                        <Col xs={12}>
                            <InputForm 
                                name="activities_detail"
                                label="Descreva com detalhes quais são os serviços e atividades prestados pela organização atualmente?"
                                type="text"
                                as="textarea"
                                min={200}
                                watch={watch}
                                rows={3}
                                errors={errors}
                                register={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <InputForm 
                                name="quantity_workers"
                                label="Qual é o número de funcionários da organização?"
                                type="number"
                                errors={errors}
                                register={register}
                            />
                        </Col>
                        <Col>
                            <InputForm 
                                name="quantity_volunteers"
                                label="Qual é o número de voluntários da organização?"
                                type="number"
                                errors={errors}
                                register={register}
                            />
                        </Col>
                        <Col>
                            <InputForm 
                                name="quantity_people_served_monthly"
                                label="Quantas pessoas a organização atende por mês?"
                                type="number"
                                errors={errors}
                                register={register}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12}>
                            <CheckBoxGroup
                                name="recess_months_create"
                                watch={watch}
                                placeholder="Selecione os Meses de Recesso (Caso não tenha, deixe em branco)"
                                label="Meses em que as atividades são paralisadas"
                                subLabel="Existe algum período de férias/ recesso / parada das atividades no qual não seja possível a coleta de doações?"
                                errors={errors}
                                register={register}
                                control={control}
                                isMulti
                                options={formatDataToSelect(dataRecessMounth)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </>
    )
}

export {CompanyProfileStep}