import { createContext, useContext } from "react"
import { useQuery } from "react-query"
import { getNewOscAnswersCount } from "./_requests"

const ListViewContext = createContext(null)

const ListViewProvider = ({ children }) => {
  
  const useGetNewOscAnswers = () => {
    return useQuery('new-osc-answers', getNewOscAnswersCount, {refetchOnWindowFocus: false})
  }

  return (
    <ListViewContext.Provider
      value={{
        useGetNewOscAnswers
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export { ListViewProvider, useListView }
