
const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v"

const getAuth = () => {
  if (!localStorage) {
    return
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth = JSON.parse(lsValue)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error)
  }
}

const setAuth = auth => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error)
  }
}

export function setupAxios(axios) {
  axios.interceptors.request.use(
    config => {
      const auth = getAuth()
      if (auth && auth.access) {
        config.headers.Authorization = `Bearer ${auth.access}`
      }
      
      return config
    },
    err => Promise.reject(err)
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
