import { Card, Col, CardGroup, Row } from "react-bootstrap"
import { useNewOsc } from "../../../_core/NewOscProvider"
import { CardCheckBox } from "../../../../../../_core/components/CardCheckBox"

const InstitutionalStep = () => {
    const { register } = useNewOsc()

    return (
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title><span className="fs-1 fw-bolder text-start">Institucional</span></Card.Title>
                            <span className="fs-4 text-start text-muted">Assinale quais destas alternativas a entidade possui</span>
                        </Col>
                    </Row>

                </Card.Header>
                <Card.Body>
                    <Row>
                        <CardGroup>
                            <CardCheckBox
                                register={register}
                                name="has_public_agreement"
                                label="Convênio com prefeituras ou outros órgãos públicos."
                            />
                            <CardCheckBox
                                register={register}
                                name="has_specific_agreement"
                                label="Convênio específico para alimentação."
                            />
                            <CardCheckBox
                                register={register}
                                name="has_updated_cnpj"
                                label={"\"Cartão CNPJ\" atualizado."}
                            />
                        </CardGroup>
                    </Row>
                    <Row>
                        <CardGroup>
                            <CardCheckBox
                                register={register}
                                name="has_updated_member_registration"
                                label={"\"Estatuto Social com RG e CPF dos membros\" atualizado."}
                            />
                            <CardCheckBox
                                register={register}
                                name="has_updated_minute_inauguration_assembly"
                                label={"\"Ata da Posse de Assembleia com RG e CPF dos membros\"  atualizado."}
                            />
                            <CardCheckBox
                                register={register}
                                name="has_updated_health_permit"
                                label={"\"Alvará Sanitário\" vigente."}
                            />
                        </CardGroup>
                    </Row>
                </Card.Body>
            </Card>

        </>
    )
}

export {InstitutionalStep}