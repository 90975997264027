import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const SelectEnumForm = ({enumProp, label, subLabel, placeholder, name, type="text", errors, register, ...rest}) => {
    const has_error = Object.keys(errors).length && errors[name]
    
    return (
        <Form.Group className="mb-3" controlId={name}>
            {label && (
                <Form.Label>
                    <Row>
                        <Col>
                            <span className='fs-5'>{label}</span>
                        </Col>
                    </Row>
                    {subLabel && (
                        <Row>
                            <Col>
                                <span className="text-muted fs-7">{subLabel}</span>
                            </Col>
                        </Row>
                    )}
                </Form.Label>
            )}
            <Form.Select
                name={name}
                isInvalid={has_error}
                {...register(name)}
                {...rest}
            >
                <option value="">Selecione uma opcão</option>
                {
                    Object.entries(enumProp).map((enumValue) => {
                        return (
                            <option value={enumValue[0]}>{enumValue[1]}</option>  
                        )
                    })
                }
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors[name]?.message}
            </Form.Control.Feedback>
            
        </Form.Group>
      );
}

export {SelectEnumForm}
