import { Button, Card, Col, Row, Spinner } from "react-bootstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { MonthAreaChart } from "./components/MonthAreaChart"
import { StatisticsWidget5 } from "../../../../../_metronic/partials/widgets"
import { useFormDetail } from "./core/FormDetailProvider"
import { CityBarChart } from "./components/CityBarChart"


const FormDetails = () => {
    const {
        useGetNewOscAnswers,
        useGetNewOscCityCount,
        useGetNewOscAnswersCountByMonth,
        useGetNewOscAnswersCountByCity,
    } = useFormDetail()
    const { isLoading: isLoadingNewOscAnswers, data: dataNewOscAnswers } = useGetNewOscAnswers()
    const { isLoading: isLoadingNewOscCityCount, data: dataNewOscCityCount } = useGetNewOscCityCount()
    const { isLoading: isLoadingNewOscAnswersCountByMonth, data: dataNewOscAnswersCountByMonth } = useGetNewOscAnswersCountByMonth()
    const { isLoading: isLoadingNewOscAnswersCountByCity, data: dataNewOscAnswersCountByCity } = useGetNewOscAnswersCountByCity()

  
    // const timesWeek = [
    //     {label: "0", value: 0},
    //     {label: "1", value: 1},
    //     {label: "2", value: 2},
    //     {label: "3", value: 3},
    //     {label: "4", value: 4},
    //     {label: "5", value: 5},
    //     {label: "6", value: 6},
    //     {label: "7", value: 7},
    // ]

    const MySwal = withReactContent(Swal)

    const handleCopyLink = () => {
        MySwal.fire({
            position: "top-end",
            backdrop: false,
            icon: "success",
            title: "Link copiado com sucesso!",
            showConfirmButton: false,
            timer: 1500
          });
    }

    return (
        <>
        <Row className="mb-3">
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <CopyToClipboard text={`${process.env.REACT_APP_BASE_DOMAIN}/forms/new_osc`}>
                                <Button variant="outline-info" onClick={handleCopyLink} className="btn btn-outline">
                                    Gerar Link
                                </Button>
                            </CopyToClipboard>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>
        {/* <Row className="mb-3">
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                        <span className="fw-bolder fs-3"> Filtros </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputForm
                                placeholder="Pesquisar OSC"
                                register={() => {}}
                                errors={errors}
                            />
                        </Col>
                        <Col>
                            <InputForm
                                placeholder="Pesquisar OSC"
                                register={() => {}}
                                errors={errors}
                            />
                        </Col>
                        <Col>
                            <MultiSelect
                                name="teste"
                                placeholder="Cidade"
                                watch={watch}
                                errors={errors}
                                register={() => {}}
                                control={control}
                                options={timesWeek}
                            />
                        </Col>
                        <Col>
                            <MultiSelect
                                name="teste"
                                placeholder="UF"
                                watch={watch}
                                errors={errors}
                                register={() => {}}
                                control={control}
                                options={timesWeek}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row> */}
        <Row>
            <Col className="m-xl-0 p-xl-0" sm={5}>
                <Card>
                    <Card.Header className="align-items-end">
                        <Row>
                            <Col xs={12}>
                                <p className="text-dark fw-bolder fs-3 text-start m-0">
                                    Respostas a formularios
                                </p>
                                <p className="text-muted fs-6 text-start">
                                {isLoadingNewOscAnswers ? "0" : dataNewOscAnswers} Respostas no periodo
                                </p>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="m-xl-0 p-xl-0">
                        {isLoadingNewOscAnswersCountByMonth ? (
                            <Col className='d-flex justify-content-center'>
                                <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
                            </Col>
                        ): (
                            <MonthAreaChart
                                chartColor='primary'
                                chartHeight='200px'
                                data={dataNewOscAnswersCountByMonth}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Col>

            <Col sm={5}>
                <Card>
                    <Card.Header className="align-items-end justify-content-start">
                        <Row>
                            <Col xs={12}>
                                <p className="text-dark fw-bolder fs-3 text-start m-0">
                                    Cidades
                                </p>
                                <p className="text-muted fs-6 text-start">
                                    Com mais respostas
                                </p>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="m-xl-0 p-xl-0">
                        {isLoadingNewOscAnswersCountByCity ? (
                            <Col className='d-flex justify-content-center'>
                                <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
                            </Col>
                        ) : (
                            <CityBarChart
                                chartColor='primary'
                                chartHeight='200px'
                                data={dataNewOscAnswersCountByCity}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Col>
            
            <Col sm={2}>
                
                <StatisticsWidget5
                    className='card-xl-stretch mb-3'
                    color='primary'
                    iconColor='white'
                    title={isLoadingNewOscAnswers ? "0" : dataNewOscAnswers}
                    description='Total de Respostas'
                />
                <StatisticsWidget5
                    className='card-xl-stretch mb-3'
                    color='primary'
                    iconColor='white'
                    title={isLoadingNewOscCityCount ? "0" : dataNewOscCityCount}
                    description='Total Cidades'
                />
            </Col>
        </Row>

        </>
    )
}

export {FormDetails}