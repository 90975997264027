import axios from "axios"

const API_URL = process.env.REACT_APP_BACKEND_API

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/me/`
export const LOGIN_URL = `${API_URL}/token/`

// Server should return AuthModel
export function login(username, password) {
  return axios.post(LOGIN_URL, {
    username,
    password
  })
}

export function getUserByToken() {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
}
