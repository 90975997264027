/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { AsideMenuItem } from "./AsideMenuItem"

export function AsideMenuMain() {

  return (
    <>
      <AsideMenuItem
        to="#"
        icon="/media/icons/duotune/art/art002.svg"
        title="Dashboard"
        fontIcon="bi-app-indicator"
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Paginas
          </span>
        </div>
      </div>
      <AsideMenuItem
        to="#"
        icon="/media/icons/duotune/abstract/plus-circle.svg"
        title="Criar Formulario"
        fontIcon="bi-app-indicator"
        className="disabled"
      />
      <AsideMenuItem
        to="/forms/list"
        icon="/media/icons/duotune/abstract/clipboard-svgrepo-com.svg"
        title="Formularios Cadastrados"
        fontIcon="bi-app-indicator"
      />
    </>
  )
}
