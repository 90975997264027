import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { ScrollTop } from "./components/ScrollTop"
import { Content } from "./components/Content"
import { PageDataProvider } from "./core"
import { useLocation } from "react-router-dom"
import {
  Main,
} from "../partials"
import { MenuComponent } from "../assets/ts/components"
import { toAbsoluteUrl } from "../helpers"

const MasterLayoutForm = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid"  style={{minHeight: "100%"}}>
        {/* <AsideDefault /> */}
        <div
          className=" d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
          style={{backgroundImage: `url(${toAbsoluteUrl("https://connectingfood.com/wp-content/uploads/2023/05/header.png")})` }}
        >
          {/* <HeaderWrapper /> */}

          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid"
          >
            {/* <Toolbar /> */}
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <RightToolbar /> */}
      {/* <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      {/* <InviteUsers /> */}
      {/* <UpgradePlan /> */}
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayoutForm }
