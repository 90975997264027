import { Col, Form, Row } from "react-bootstrap"


const RadioButton = ({name, register, label, subLabel, id, ...rest}) => {

    return(
        <>
            <Row>
                <Form.Label>
                    <Row>
                        <Col>
                            <span className='fs-5'>{label}</span>
                        </Col>
                    </Row>
                    {subLabel && (
                        <Row>
                            <Col>
                                <span className="text-muted fs-7">{subLabel}</span>
                            </Col>
                        </Row>
                    )}
                </Form.Label>
            </Row>
            <Row>
                <Col className="mt-2 mb-2">
                    <Form.Check
                        {...rest}
                        type="radio"
                        name={name}
                        {...register(name)}
                        id={`${id}_yes`}
                        label="Sim"
                        inline
                        value={true}
                        // onChange={() => setValue(name, true)}
                    />
                    <Form.Check
                        {...rest}
                        type="radio"
                        name={name}
                        {...register(name)}
                        id={`${id}_no`}
                        label="Não"
                        inline
                        value={false}
                        // onChange={() => setValue(name, false)}
                    />
                </Col>
            </Row>
        </>
    )
}

export { RadioButton }