import { PageTitle } from "../../../_metronic/layout/core"
import { FormDetails } from "../../modules/forms/admin/forms-detail/FormsDetail"
import { FormDetailProvider } from "../../modules/forms/admin/forms-detail/core/FormDetailProvider"


const FormDetailsWrapper = () => {
    return (
        <>
        <FormDetailProvider>
            <PageTitle breadcrumbs={[]}>
            Backlog OSCs
            </PageTitle>
            <FormDetails />
        </FormDetailProvider>
        </>
    )
}

export {FormDetailsWrapper}