import { Card, Col, Row, Spinner } from "react-bootstrap"
import { InputForm } from "../../../../../../_core/components/InputForm"
import { MultiSelect } from "../../../../../../_core/components/MultiSelect"
import { useNewOscQuery } from "../../../_core/NewOscQueryProvider"
import { useNewOsc } from "../../../_core/NewOscProvider"
import { ConditionalCheckForm } from "../../../../../../_core/components/ConditionalCheckForm"
import { disponibilityCollectionEnum } from "../enum/DisponibilityCollectionEnum"
import { SelectEnumForm } from "../../../../../../_core/components/SelectEnumForm"
import { CheckBoxGroup } from "../../../../../../_core/components/CheckBoxGroup"
const ComplementarInformationStep = () => {
    const { register, errors, control, watch, setValue, formatDataToSelect } = useNewOsc()
    const { 
        useGetOtherPartnerFoodType,
        useGetOtherPartnerType,
        useGetOtherPartnerFrequencyReceved,
        useGetDonationExtractionTransportType
    } = useNewOscQuery()
    
    const { isLoading: isLoadingOtherPartnerFoodType, data: dataOtherPartnerFoodType } = useGetOtherPartnerFoodType()
    const { isLoading: isLoadingOtherPartnerType, data: dataOtherPartnerType } = useGetOtherPartnerType()
    const { isLoading: isLoadingOtherPartnerFrequencyReceved, data: dataOtherPartnerFrequencyReceved } = useGetOtherPartnerFrequencyReceved()
    const { isLoading: isLoadingDonationExtractionTransportType, data: dataDonationExtractionTransportType } = useGetDonationExtractionTransportType()

    
    if(isLoadingOtherPartnerFoodType || isLoadingOtherPartnerType || isLoadingOtherPartnerFrequencyReceved || isLoadingDonationExtractionTransportType){
        return (
            <>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <p className='fs-3 fw-bolder'>Carregando... </p>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title><span className="fs-1 fw-bolder text-start">Informações Complementares</span></Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row className="mt-3 mb-3">
                        <InputForm 
                                name="collection_restriction_detail"
                                label="Em caso de recebimento de doações, há intenção para transferi-las para outra unidade ou filial? Se sim, por favor, liste as unidades ou filiais beneficiadas e seus respectivos endereços."
                                type="text"
                                as="textarea"
                                watch={watch}
                                rows={3}
                                errors={errors}
                                register={register}
                            />
                    </Row>
                    <Row className="mt-3 mb-3">
                        <InputForm 
                                name="donation_destination"
                                label="Caso existam restrições para a coleta de doações, por favor, descreva-as no campo abaixo."
                                type="text"
                                as="textarea"
                                watch={watch}
                                rows={3}
                                errors={errors}
                                register={register}
                            />
                    </Row>
                    <Row className="mt-3 mb-3">
                        <SelectEnumForm
                            enumProp={disponibilityCollectionEnum}
                            name="disponibility_collection"
                            label="Qual seria a disponibilidade da instituição para coletar as  doações?"
                            subLabel="Considerando que a coleta seja feita próxima a OSC."
                            errors={errors}
                            register={register}
                        />
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <ConditionalCheckForm
                                label="Recebem doações de alimentos de outros parceiros?"
                                id="has_other_parters"
                                name="other_parters.has_other_parters"
                                register={register}
                                setValue={setValue}
                                watch={watch}
                            >
                                <CheckBoxGroup
                                    name="other_parters.food_type_create"
                                    placeholder="Quais tipos de alimentos vocês recebem?"
                                    label="Selecione os tipos de alimento (mais de uma alternativa pode ser selecionada)"
                                    watch={watch}
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    customMd={4}
                                    isMulti
                                    isCreatable
                                    options={formatDataToSelect(dataOtherPartnerFoodType)}
                                />
                                <CheckBoxGroup
                                    name="other_parters.partner_type_create"
                                    placeholder="De quem recebem?"
                                    label="Selecione os parceiros (mais de uma alternativa pode ser selecionada)"
                                    subLabel="Caso o seu parceiro não esteja na lista, o adicione ao campo abaixo"
                                    watch={watch}
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    customMd={3}
                                    isMulti
                                    isCreatable
                                    options={formatDataToSelect(dataOtherPartnerType)}
                                />
                                <MultiSelect
                                    name="other_parters.frequency_receved_create"
                                    placeholder="Com que frequência recebem?"
                                    label="Selecione a frequência"
                                    watch={watch}
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    options={formatDataToSelect(dataOtherPartnerFrequencyReceved)}
                                />
                            </ConditionalCheckForm>
                        </Col>
                    </Row>
                    
                    <Row className="mt-3 mb-3">
                        <Col>
                            <ConditionalCheckForm
                                label="Possui transporte para retirar as doações?"
                                id="has_donation_extraction"
                                name="donation_extraction.has_donation_extraction"
                                register={register}
                                setValue={setValue}
                                watch={watch}
                            >
                            <MultiSelect 
                                name="donation_extraction.type_of_transport_create"
                                label="Especifique se o transporte é próprio ou de terceiros"
                                placeholder="Selecione uma opção?"
                                type="text"
                                errors={errors}
                                register={register}
                                watch={watch}
                                control={control}
                                isCreatable
                                options={formatDataToSelect(dataDonationExtractionTransportType)}
                            />
                            <InputForm 
                                name="donation_extraction.vehicle_description"
                                label="Que veículo (Tipo, marca) seria utilizado para coleta?"
                                placeholder="Exemplo - Carro de passeio (comum); Van, Picape, Kombi, Caminhão, etc. "
                                type="text"
                                errors={errors}
                                register={register}
                            />
                            </ConditionalCheckForm>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export {ComplementarInformationStep}