import axios from "axios"

const API_URL = process.env.REACT_APP_BACKEND_API

const getNewOscAnswersCount = async () => {
  return axios.get(`${API_URL}/new-osc-form/count/`).then(d => d.data)
}
const getNewOscCityCount = async () => {
  return axios.get(`${API_URL}/new-osc-form/count_city/`).then(d => d.data)
}
const getNewOscAnswersCountByMonth = async () => {
  return axios.get(`${API_URL}/new-osc-form/count_by_month/`).then(d => d.data)
}
const getNewOscAnswersCountByCity = async () => {
  return axios.get(`${API_URL}/new-osc-form/count_by_city/`).then(d => d.data)
}

export {
  getNewOscAnswersCount,
  getNewOscCityCount,
  getNewOscAnswersCountByMonth,
  getNewOscAnswersCountByCity,
}
