import { Navigate } from "react-router-dom";
import { AuthLayout } from "../modules/auth/AuthPage";
import { Login } from "../modules/auth/components/Login";


export const authRoutes = {
    path: "/*",
    element: <AuthLayout/>,
    children: [
        {
            path: "login",
            element: <Login/>
        },
        {
            path: "*",
            element: <Navigate to="/login"/>
        }
    ]
}