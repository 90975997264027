/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from "react-router-dom"
import { Col, Row, Spinner } from "react-bootstrap"

import { KTSVG } from "../../../helpers"
import { useListView } from "../../../../app/modules/forms/admin/forms-list/core/ListViewProvider"

const TablesWidget13 = ({ className }) => {
  const { useGetNewOscAnswers } = useListView()

  const { isLoading: isLoadingNewOscAnswers, data: dataNewOscAnswers } = useGetNewOscAnswers()

  if(isLoadingNewOscAnswers){
    return (
      <>
          <Row>
              <Col className='d-flex justify-content-center'>
                  <Spinner animation="grow" variant="primary" style={{ width: "6rem", height: "6rem" }} />
              </Col>
          </Row>
          <Row>
              <Col className='d-flex justify-content-center'>
                  <p className='fs-3 fw-bolder'>Carregando... </p>
              </Col>
          </Row>
      </>
    )
  }

  console.log(dataNewOscAnswers)


  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-25px">
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      data-kt-check="true"
                      data-kt-check-target=".widget-13-check"
                    />
                  </div>
                </th>
                <th className="min-w-150px">FORMULARIO</th>
                <th className="min-w-140px">N. DE RESPOSTAS</th>
                <th className="min-w-120px">DATA DA CRIACAO</th>
                <th className="min-w-120px">DATA DA ULTIMA ATUALIZACAO</th>
                <th className="min-w-120px">STATUS</th>
                <th className="min-w-100px text-end">ACOES</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input widget-13-check"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td>
                  
                  <Link
                    to="/forms/new-osc"
                    className="text-dark fw-bolder text-hover-primary fs-6"
                  >
                    Backlog OSCs
                  </Link>
                </td>
                <td>
                  <span
                    className="text-dark fw-bolder d-block mb-1 fs-6"
                  >
                    {dataNewOscAnswers}
                  </span>
                </td>
                <td>
                  <span
                    className="text-dark fw-bolder d-block mb-1 fs-6"
                  >
                    --/--/----
                  </span>
                </td>
                <td>
                  <span
                    className="text-dark fw-bolder d-block mb-1 fs-6"
                  >
                    --/--/----
                  </span>
                </td>
                <td>
                  <span className="badge badge-light-success">Ativo</span>
                </td>
                <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTSVG
                      path="/media/icons/duotune/art/art005.svg"
                      className="svg-icon-3"
                    />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <KTSVG
                      path="/media/icons/duotune/general/gen027.svg"
                      className="svg-icon-3"
                    />
                  </a>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget13 }
