import { Card, Col, Row } from "react-bootstrap"
import InputMask from 'react-input-mask';

import { InputForm } from "../../../../../../_core/components/InputForm"
import { useNewOsc } from "../../../_core/NewOscProvider";
import { priorityCategoryEnum } from "../enum/PriorityCategoryEnum";
import { ResponsableForm } from "../ResponsableForm";
import { SelectEnumForm } from "../../../../../../_core/components/SelectEnumForm";


const GeneralDataStep = () => {
    const { register, errors, handlePhoneMaskChange } = useNewOsc()
    return (
        <>
        <Card>
            <Card.Header>
                <Card.Title>
                    <span className="fs-1 fw-bolder text-start"> Dados Gerais</span>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <InputForm 
                            label="CNPJ"
                            placeholder="Insira o seu cnpj"
                            name="cnpj"
                            type="text"
                            mask="99.999.999/9999-99"
                            as={InputMask}
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col>
                        <InputForm 
                            name="razao_social"
                            label="Razão Social"
                            type="text"
                            disabled
                            readonly
                            errors={errors}
                            register={register}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <InputForm 
                            placeholder="Insira o CEP"
                            label="CEP"
                            name="cep"
                            type="text"
                            mask="99999-999"
                            as={InputMask}
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col sm={5}>
                        <InputForm 
                            name="street"
                            label="Rua"
                            type="text"
                            disabled
                            readonly
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col sm={2}>
                        <InputForm 
                            name="street_number"
                            label="Número"
                            type="text"
                            errors={errors}
                            register={register}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <InputForm 
                            label="Bairro"
                            name="neighborhood"
                            type="text"
                            disabled
                            readonly
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col sm={2}>
                        <InputForm 
                            label="Estado"
                            name="state"
                            type="text"
                            disabled
                            readonly
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col sm={4}>
                        <InputForm 
                            name="city"
                            label="Cidade"
                            type="text"
                            disabled
                            readonly
                            errors={errors}
                            register={register}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputForm
                            name="phone"
                            label="Telefone"
                            type="text"
                            mask={handlePhoneMaskChange()}
                            as={InputMask}
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col>
                        <InputForm 
                            name="organization_site"
                            label="Site"
                            type="text"
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col>
                        <InputForm 
                            name="social_midia"
                            label="Rede Social"
                            type="text"
                            errors={errors}
                            register={register}
                        />
                    </Col>
                    <Col>
                        <SelectEnumForm
                            enumProp={priorityCategoryEnum}
                            name="priority_category"
                            label="Categoria Prioritária"
                            errors={errors}
                            register={register}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="shadow-lg p-3 mb-5 bg-body rounded">
                            <Card.Body>
                                <Card.Title>Responsável</Card.Title>
                                <ResponsableForm
                                    errors={errors}
                                    register={register}
                                    handlePhoneMaskChange={handlePhoneMaskChange}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export {GeneralDataStep}