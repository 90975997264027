/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import {
  TablesWidget13
} from "../../../_metronic/partials/widgets"
import { ListViewProvider } from "../../modules/forms/admin/forms-list/core/ListViewProvider"

const RegisteredFormsPage = () => (
  <>
    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      <div className="col-xs-12">
        <TablesWidget13 className='mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    
  </>
)

const RegisteredFormsWrapper = () => {
  return (
    <>
      <ListViewProvider>
        <PageTitle breadcrumbs={[]}>
          Formularios Cadastrados
        </PageTitle>
        <RegisteredFormsPage />
      </ListViewProvider>
    </>
  )
}

export { RegisteredFormsWrapper }
