import { Form } from "react-bootstrap"
import { Children } from "react"

const ConditionalCheckForm = ({children, name, register, watch, setValue, label, id, ...rest}) => {

    const mappedChidren = Children.toArray(children)

    return(
        <>
            <span>{label}</span>
            <div className="mt-2">
                <Form.Check
                    {...rest}
                    type="radio"
                    name={name}
                    {...register(name)}
                    id={`${id}_yes`}
                    label="Sim"
                    inline
                    value={true}
                    // onChange={() => setValue(name, true)}
                />
                <Form.Check
                    {...rest}
                    type="radio"
                    name={name}
                    {...register(name)}
                    id={`${id}_no`}
                    label="Não"
                    inline
                    value={false}
                    // onChange={() => setValue(name, false)}
                />
            </div>
            {watch(name) === "true" && Object.keys(mappedChidren).map((key) => {
                return mappedChidren[key]
            })}
        </>
    )
}

export { ConditionalCheckForm }