import {createContext, useContext } from "react"

import {
  getTargetAudience,
  getRecessMounth,
  getAgeGroup,
  getMealType,
  getDistributionType,
  getEducatinalUsageType,
  getOtherPartnerFoodType,
  getOtherPartnerType,
  getOtherPartnerFrequencyReceved,
  getDonationExtractionTransportType,

} from "./_requests"
import { useQuery } from "react-query"


const NewOscQueryContext = createContext(null)

const NewOscQueryProvider = ({ children }) => {

    const useGetTargetAudience = () => {
      return useQuery('target-audience', getTargetAudience, {refetchOnWindowFocus: false})
    }
    
    const useGetRecessMounth = () => {
      return useQuery('recess-mounth', getRecessMounth, {refetchOnWindowFocus: false})
    }
    
    const useGetAgeGroup = () => {
      return useQuery('age-group', getAgeGroup, {refetchOnWindowFocus: false})
    }
    
    const useGetMealType = () => {
      return useQuery('meal-type', getMealType, {refetchOnWindowFocus: false})
    }
    const useGetDistributionType = () => {
      return useQuery('distribution-type', getDistributionType, {refetchOnWindowFocus: false})
    }
    
    const useGetEducatinalUsageType = () => {
      return useQuery('education-type', getEducatinalUsageType, {refetchOnWindowFocus: false})
    }
    const useGetOtherPartnerFoodType = () => {
      return useQuery('other-partner-food-type', getOtherPartnerFoodType, {refetchOnWindowFocus: false})
    }
    const useGetOtherPartnerType = () => {
      return useQuery('other-partner-type', getOtherPartnerType, {refetchOnWindowFocus: false})
    }
    const useGetOtherPartnerFrequencyReceved = () => {
      return useQuery('other-partner-frequency-receved', getOtherPartnerFrequencyReceved, {refetchOnWindowFocus: false})
    }
    
    const useGetDonationExtractionTransportType = () => {
      return useQuery('donation-extraction-transport-type', getDonationExtractionTransportType, {refetchOnWindowFocus: false})
    }

    return (
      <NewOscQueryContext.Provider
        value={{
          useGetTargetAudience,
          useGetRecessMounth,
          useGetAgeGroup,
          useGetMealType,
          useGetDistributionType,
          useGetEducatinalUsageType,
          useGetOtherPartnerFoodType,
          useGetOtherPartnerType,
          useGetOtherPartnerFrequencyReceved,
          useGetDonationExtractionTransportType,
        }}
      >
        {children}
      </NewOscQueryContext.Provider>
    )
}

const useNewOscQuery = () => useContext(NewOscQueryContext)

export { NewOscQueryProvider, useNewOscQuery }
