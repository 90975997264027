import axios from "axios"

const API_URL = process.env.REACT_APP_BACKEND_API

const getNewOscAnswersCount = async () => {
  return axios.get(`${API_URL}/new-osc-form/count/`).then(d => d.data)
}

export {
  getNewOscAnswersCount
}
