import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'

import AppRoutes from './app/routing/AppRouting';
import { AuthProvider } from './app/modules/auth/core/Auth';
import { setupAxios } from './app/modules/auth/core/AuthHelpers';

const root = ReactDOM.createRoot(document.getElementById('root'));

setupAxios(axios)

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient} >
      <AuthProvider>
        <AppRoutes/>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
